import { Component } from '@angular/core';

@Component({
    selector: 'body[root]',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {

    // Declare and Initialize Application title
    title = 'bullymake-analytics';
    
}
