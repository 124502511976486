import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { BullymakeError } from "../core/types/error-handler";

@Injectable({
	providedIn: 'root'
})
export class BullymakeErrorHandler implements ErrorHandler {

    public readonly env = environment;

    constructor(private http: HttpClient) {
        
    }

    handleError(error: Error): void {
        
        if( error instanceof HttpErrorResponse ) return;

        if(!this.env.production){
            console.log("Handle Error: ", error);
        }

        const logErrorPayload: BullymakeError = {
            'source': 'bullymake-website',
            'error_type': 'error',
            'stack': error.stack ? error.stack : '',
            'message': error.message?error.message :'',
            'code':'',
        };

        this.logError(logErrorPayload).subscribe((data) => {
            
        });
    }

    logError(error: BullymakeError) {

        return this.http.post(this.env.rootUrl + '/api/v3/log-error', error)
    }
}