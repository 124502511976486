import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    /**
     * Dependency injection of services
     * @param service AuthService
     * @param router Router
     */
    constructor(private service: AuthService, private router: Router, private snackbar: MatSnackBar) { }

    /**
     * Intercepting all http requests
     * @param request HttpRequest
     * @param next HttpHandler
     * @returns Observable<HttpEvent<unknown>>
     */
    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

        // Get saved token from local storage
        let tokenObj = this.service.getSavedToken();

        // Check if the request is of jwt-auth and we have a valid token in local storage
        if (request.url.search('wp-json/jwt-auth/v1/token') < 0 && tokenObj && tokenObj.token) {

            // Create a copy of request and override by setting a authorization header for every request
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${tokenObj.token}`
                }
            });
        }

        return next.handle(request).pipe(
            catchError((err: any) => {

                // If we have an error of HttpErrorResponse then
                if (err instanceof HttpErrorResponse) {

                    try {

                        if ((err.status == 401 || err.status == 403)) {
                            // If we're not allowed to see this route and the API returns an error, we check the error.
                            // If error is about invalid JWT, it means we're not correctly logged in and we logout the current user.
                            if (err.status == 403 && err?.error?.code === "jwt_auth_invalid_token") {
                              this.service.logout();
                            } else {
                              // Show Error Snackbar
                              this.snackbar.open(err.error.message, 'Close', {
                                duration: 3500,
                              });
                            }
                        }
                    } catch (e) {
                    }
                }

                // If it is not HttpErrorResponse Error then return as it is
                return next.handle(request);

            })
        );

    }

}
