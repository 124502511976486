import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Token } from "../core/types/auth/token";
import { map } from 'rxjs/operators';
import { Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable({
    providedIn: 'root',
})
export class AuthService {

    // Initialize the root url
    readonly ROOT_URL: string = environment.rootUrl;

    // Initialize the token key for local storage
    readonly TOKEN_KEY: string = environment.tokenKey;

    /**
     * Dependancy Injection of Services
     * @param http HttpClient
     * @param router Router
     * @param snackBar MatSnackBar
     */
    constructor(private http: HttpClient, private router: Router, private snackBar: MatSnackBar) {
    }

    /**
     * Get Token by sending a POST request
     * @param email string
     * @param password string
     * @returns Observable<any>
     */
    getToken(email: string, password: string): Observable<any> {

        return this.http.post<Token>(
            this.ROOT_URL + '/jwt-auth/v1/token',
            { username: email, password: password })
            .pipe(
                map((response: any) => response)
            );
    }

    /**
     * Save token in local storage
     * @param token string
     * @returns 
     */
    saveToken(token: string): void {

        // Save tokem to local storage
        localStorage.setItem(this.TOKEN_KEY, JSON.stringify(token));

    }

    /**
     * Get Saved Token from local storage
     */
    getSavedToken(): Token {

        return JSON.parse(localStorage.getItem(this.TOKEN_KEY) as string);

    }

    /**
     * Remove Saved Token from local storage
     */
    removeSavedToken(): void {

        localStorage.removeItem(this.TOKEN_KEY);

    }

    /**
     * Logout the user by removing the token from local storage
     * and redirecting back to the login screen
     */
    logout() {

        // Remove saved token from local storage
        this.removeSavedToken();

        // Navigate back to login screen
        this.router.navigate(['/auth/login']);

    }

    /**
     * Login the user by saving the token to local storage
     * and redirecting back to the dashboard
     * @param response any
     */
    login(response: any) {

        // Save the token to local storage
        this.saveToken(response)

        // Go back to the dashboard
        this.router.navigate(['/'])

    }
}