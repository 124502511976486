import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { Token } from "../core/types/auth/token";
import { AuthService } from "./auth.service";

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    /**
     * Dependancy injection of services
     * @param service AuthService
     */
    constructor(private service: AuthService) {
    }

    /**
     * Authenticates the user and only allow to access the routes as required
     * @param route ActivatedRouteSnapshot
     * @param state RouterStateSnapshot
     * @returns boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree>
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {

        // Get Saved token from local storage
        let tokenObj: Token = this.service.getSavedToken();

        // If it exists, then give entry to the user inside dashboard
        if (tokenObj && tokenObj.token) return true;

        // If not , then go back to login screen by logging out
        this.service.logout();

        return false;
    }
}