import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';

export const routes: Routes = [
	{
		path: "auth",
		loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule)
	},
	{
		path: "",
		canActivate: [AuthGuard],
		loadChildren: () => import("./layout/layout.module").then((m) => m.LayoutModule)
	},
	{
		path: "errors",
		loadChildren: () => import("./errors/errors.module").then((m) => m.ErrorsModule)
	},
	{
		path: "**",
		redirectTo: "errors/404"
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
